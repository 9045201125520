import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { apiRequest } from '../api';
import './BackgammonGame.css';
import audioManager from './AudioManager';

const BackgammonGame = () => {
    const [renderTrigger, setRenderTrigger] = useState(false);
    const { socket, user } = useContext(AuthContext);
    const navigate = useNavigate();
    const [game, setGame] = useState(null);
    const [boardState, setBoardState] = useState([]);
    const [dice, setDice] = useState([0, 0]);
    const [isRolling, setIsRolling] = useState(false);
    const [selectedChecker, setSelectedChecker] = useState(null);
    const [isGameEnd, setIsGameEnd] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [opponents, setOpponents] = useState([]);
    const [ShowCancelPopup, setShowCancelPopup] = useState(false);
    const DEFAULT_VOLUME = 0.8;
    const isPlayerWaitingRef = useRef(false);
    const [isSwapping, setIsSwapping] = useState(false);
    const [usedDice, setUsedDice] = useState([false, false]); 
    const [isAnimating, setIsAnimating] = useState(false);
    const [animatingCheckers, setAnimatingCheckers] = useState({});
    const [moves, setMoves] = useState([]); 
    const [isMoveConfirmed, setIsMoveConfirmed] = useState(false);
    const isGameUpdatedRef = useRef(false);



    function playBackgammonSound(soundName) {
        audioManager.setVolume(DEFAULT_VOLUME);
        audioManager.playSound(soundName);
    }

    
    const boardRef = useRef([]);  // Store the board data in a ref

    
    useEffect(() => {
        if (!game) return;

        boardRef.current = game.board;  // Storing the initial board state in the ref
    }, [game?.board]);

    


    const fetchGameState = useCallback(async () => {
            try {
                const response = await apiRequest('/api/current-game', {
                    method: 'GET',
                    credentials: 'include',
                });
                const data = await response.json();
                if (response.ok && data.game) {
                    if (data.game.status === 'completed') {
                        navigate('/game');
                        return;
                    }
                    console.log('data on fetch',data)
                    setIsAnimating(false);
                    setUsedDice([false, false]);
                    setMoves([]);
                    capturedCheckersRef.current = [];
                    setOpponents(data.opponents || []);
                    setGame(data.game);
                    setBoardState(data.game.board);
                    handleGamePhase(data);
                } else {
                    setGame(null);
                    navigate('/game');
                    return;
                }
            } catch (error) {
                console.error('Error fetching game state:', error);
                setGame(null);
                navigate('/game');
                return;
            }
        }, [navigate]);

        useEffect(() => {
            const handleVisibilityChange = () => {
                if (!document.hidden) {
                    fetchGameState();
                }
            };
        
            document.addEventListener('visibilitychange', handleVisibilityChange);
        
            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }, [fetchGameState]); 
    
        useEffect(() => {
            fetchGameState();
        }, [fetchGameState]);

     

     useEffect(() => {
            if (!user) {
                navigate('/login');
                return;
            }
    
            socket.on('connect', () => {
                fetchGameState();
            });
    
            
    
            socket.on('game-update', (data) => {
                if (!data.game) {
                    navigate('/game');
                    return;
                }
    
                console.log('data on game update',data)
                setOpponents(data.opponents || []);
                if(!data.firstUpdate){
                    setGame(data.game);
                }
                
                setBoardState(data.game.board);
                isGameUpdatedRef.current = true;
                handleGamePhase(data);
    
            });
    
            return () => {
                socket.off('game-update');
                socket.off('connect');
                
            };
        }, [socket, user, navigate]);

    // handleGamePhase function to handle different game phases
const handleGamePhase = (data) => {
    switch (data.game.phase) {
        case 'game-start':

            handleRollAnimation(data.game);
            
            break;
        case 'roll-dice':
            
            break;

        case 'move-checker':
            setSelectedChecker(null); 
            if(!data.firstUpdate){
                handleRollAnimation(data.game);
            } else {
                handleDiceShiftAnimation(data.game);
            }
            
            break;

        case 'game-completed':
            setIsGameEnd(true); // Set game to complete
            break;

        default:
            console.warn(`Unhandled game phase: ${data.game.phase}`);
    }
};




const handleRollAnimation = (currentGame = null) => {
    if (isPlayerWaitingRef.current) return;
    if (isGameUpdatedRef.current){
        isGameUpdatedRef.current = false;
        setDice(currentGame?.dice);

        return;
    }
    setIsRolling(true);

    const interval = setInterval(() => {
        setDice([Math.floor(Math.random() * 6) + 1, Math.floor(Math.random() * 6) + 1]);
    }, 70);

    setTimeout(() => {
        clearInterval(interval);
        setDice([Math.floor(Math.random() * 6) + 1, Math.floor(Math.random() * 6) + 1]);
        setIsRolling(false);
        if (currentGame){
           // setDice(currentGame.dice);
           setDice([4, 2])
        } else {
            isPlayerWaitingRef.current = true;
        }
    }, 1500);
};


const handleDiceShiftAnimation = (currentGame) => {
    const isPlayerTurn = currentGame.turn === user._id;

    if (isPlayerTurn) {
        const losingDieIndex = 1;
        animateLosingDie(losingDieIndex, currentGame);
    } else {
        const playerLosingDieIndex = 1; // Player's first die
        animatePlayerLosingDie(playerLosingDieIndex, currentGame);
    }
};


const animateLosingDie = (losingDieIndex, currentGame) => {
    const dieElement = document.querySelector(`.backgammon-dice-container.opponent-dice .backgammon-dice${losingDieIndex + 1}`);
    
    if (!dieElement) {
        setGame(currentGame);
        return; 
    }

    // Calculate the shift distance
    const shiftX = 213.43;
    const shiftY = 0;

    dieElement.style.setProperty('--shift-x', `${shiftX}px`);
    dieElement.style.setProperty('--shift-y', `${shiftY}px`);
    dieElement.classList.add('shift-die');

    setTimeout(() => {
        dieElement.classList.remove('shift-die');
        setGame(currentGame);

    }, 800); 
};



const animatePlayerLosingDie = (playerLosingDieIndex, currentGame) => {
    const dieElement = document.querySelector(`.backgammon-dice-container.player-dice .backgammon-dice${playerLosingDieIndex + 1}`);
    
    if (!dieElement) {
        setGame(currentGame);
        return;
    }

    // Calculate the shift distance
    const shiftX = -129.7;
    const shiftY = 0;

    dieElement.style.setProperty('--shift-x', `${shiftX}px`);
    dieElement.style.setProperty('--shift-y', `${shiftY}px`);
    dieElement.classList.add('shift-die');

    setTimeout(() => {
        dieElement.classList.remove('shift-die');
        setGame(currentGame);
    }, 800);
};


const handleDiceClick = () => {
    if (isRolling || isSwapping || usedDice[0] || usedDice[1]) return;

    setIsSwapping(true);  

    const dice1Element = document.querySelector('.backgammon-dice1');
    const dice2Element = document.querySelector('.backgammon-dice2');
    
    const dice1Position = dice1Element.getBoundingClientRect();
    const dice2Position = dice2Element.getBoundingClientRect();
    const shiftX = dice2Position.left - dice1Position.left;

    dice1Element.style.setProperty('--shift-x', `${shiftX}px`);
    dice2Element.style.setProperty('--shift-x', `-${shiftX}px`);
    dice1Element.classList.add('switch-backgammon-dice');
    dice2Element.classList.add('switch-backgammon-dice');

    setTimeout(() => {
      const newDice = [dice[1], dice[0]]; 
      setDice(newDice);
      setIsSwapping(false);  

      dice1Element.classList.remove('switch-backgammon-dice');
      dice2Element.classList.remove('switch-backgammon-dice');
    }, 500);  
  };
  
  
  const handlePointClick = (pointIndex, diceValue, currentGame = game) => {
    if (isAnimating) {
        return;
    }
    if (currentGame?.turn !== user._id || isRolling) {
        return;  
    }

    const pointNumber = pointIndex + 1;

    const isDoubleDice = dice[0] === dice[1];

    const availableDice = usedDice[0] >= (isDoubleDice ? 2 : 1) ? (usedDice[1] >= (isDoubleDice ? 2 : 1) ? null : 1) : 0; // Decide the available dice to play

    if (availableDice === null) {
        return;  
    }

    const diceToUse = dice[availableDice];

    const checker = getCheckerAtPoint(pointIndex, currentGame);
    if (!checker) {
        return;
    }

    const currentPlayerColor = currentGame?.playerColors[user._id];

    if (currentPlayerColor === 'white' && checker.owner.toString() !== user._id.toString()) {
        return;
    }

    if (currentPlayerColor === 'black' && checker.owner.toString() === user._id.toString()) {
        return;
    }

    
     setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: true }));

    const targetPoint = pointNumber + diceToUse; 

    const isMoveValidForDice = isMoveValid(checker, targetPoint, currentGame);

    if (!isMoveValidForDice) {
        const otherDiceIndex = availableDice === 0 ? 1 : 0; 
        const otherDiceValue = dice[otherDiceIndex]; 
        const targetPointForOtherDice = pointNumber + otherDiceValue;

        if (usedDice[otherDiceIndex] < (isDoubleDice ? 2 : 1) && isMoveValid(checker, targetPointForOtherDice, currentGame)) {
            setIsAnimating(true);
            moveChecker(checker, targetPointForOtherDice, otherDiceValue, capturedCheckersRef.current.length >0, () => {
                    setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: false }));
                    setIsAnimating(false);
            });
            if (capturedCheckersRef.current.length >0){
                setIsAnimating(true);
                const captured = capturedCheckersRef.current[0];  
            moveChecker(captured.checker, captured.bitAreaPoint, 0, false, () => {
                capturedCheckersRef.current = [];
                setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: false }));
                setIsAnimating(false); 
            });       
            }

            setUsedDice(prevState => {
                const newUsedDice = [...prevState];
                newUsedDice[otherDiceIndex] += 1; 
                return newUsedDice;
            });
            return;  
        } else {
            return;  
        }
    }

    
    

    setIsAnimating(true);

    // If the move is valid, perform the move
    moveChecker(checker, targetPoint, diceToUse, capturedCheckersRef.current.length >0, () => {
            
        setIsAnimating(false);   
        setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: false }));      
    });

    if (capturedCheckersRef.current.length >0) {
        setIsAnimating(true);
        const captured = capturedCheckersRef.current[0];    
    moveChecker(captured.checker, captured.bitAreaPoint, 0, false, () => {
        capturedCheckersRef.current = [];
        setIsAnimating(false); 
    });       
    }

    

    // Increment the usage count for the dice
    setUsedDice(prevState => {
        const newUsedDice = [...prevState];
        newUsedDice[availableDice] += 1; // Increment the usage count
        return newUsedDice;
    });
    
    
    
    
};
  



  
const getCheckerAtPoint = (pointIndex, currentGame) => {
    const point = boardRef.current[pointIndex];
    if (!point) return null;

    const currentPlayerColor = currentGame.playerColors[user._id];
    let checker = null;

    if (currentPlayerColor === 'white') {
        
        checker = point.checkers.filter(c => c.owner.toString() === user._id.toString()).pop();
    }

    if (currentPlayerColor === 'black') {
        
        checker = point.checkers.filter(c => c.owner.toString() !== user._id.toString()).pop();
    }
    
    if (checker) {
        return { ...checker, point: pointIndex };
    }

    return null; 
};






const capturedCheckersRef = useRef([])


const isMoveValid = (checker, targetPoint, currentGame = game) => {
    // Find the target point by matching its point number (1 to 24)
    const target = boardRef.current.find(point => point.point === targetPoint);
    if (!target) return false;  // If the target point is invalid, return false

    const currentPlayerColor = currentGame?.playerColors[user._id];

    // Check if the target point is occupied by an opponent's checker
    const opponentCheckers = target.checkers.filter(c => {
        if (currentPlayerColor === 'white') {
            return c.owner.toString() !== user._id.toString();  // Opponent's checker for white player
        }
        if (currentPlayerColor === 'black') {
            return c.owner.toString() === user._id.toString();  // Opponent's checker for black player
        }
    });

    // If the target is occupied by more than one opponent checker, the point is secured, and the move is invalid
    if (opponentCheckers.length > 1) {
        return false;
    }

    // If the target is occupied by exactly one opponent checker, the move is valid (capture)
    if (opponentCheckers.length === 1) {
     
        const capturedChecker = opponentCheckers[0];
        capturedCheckersRef.current.push({
            checker: capturedChecker,
            bitAreaPoint: 25,  // Save the target bit area where it needs to be moved
            originalPoint: targetPoint
        });
        return true;
    }

    // If the target is empty or occupied by the player's own checkers, the move is valid
    return true;
};




const moveChecker = (checker, targetPoint, diceValue, isCaptured, callback) => {
    const currentPoint = boardRef.current.find((point) =>
        point.checkers.some((c) => c._id === checker._id)
    );
    const targetPointObj = boardRef.current.find((point) => point.point === targetPoint);

    if (!currentPoint || !targetPointObj) {
        console.error('Invalid move: point not found');
        callback();
        return;
    }

    const checkerElement = document.querySelector(`[data-checker-id="${checker._id}"]`);
    const targetElement = document.querySelector(`[data-point-id="${targetPoint}"]`);

    if (!checkerElement || !targetElement) {
        console.error('Checker or target element not found', checkerElement, targetElement);
        callback();
        return;
    }

    const currentPos = checkerElement.getBoundingClientRect();
    const targetPos = targetElement.getBoundingClientRect();

    const targetStackLength = isCaptured 
        ? targetPointObj.checkers.length - 1 
        : targetPointObj.checkers.length;

    const checkerHeight = 22;
    const maxCheckersWithoutOverlap = 4;
    const stackOffset = targetStackLength <= maxCheckersWithoutOverlap
        ? targetStackLength * checkerHeight 
        : maxCheckersWithoutOverlap * checkerHeight;

        const isCapturedPoint = targetPoint === 25 || targetPoint === 26;

        let targetY;
        if (isCapturedPoint) {
            targetY = targetPos.bottom - checkerHeight; // Force all checkers to the same position
        } else if (targetPoint <= 12) {
            targetY = targetPos.top + stackOffset;
        } else {
            targetY = targetPos.bottom - checkerHeight - stackOffset;
        }
        

    const shiftX = targetPos.left - currentPos.left + (targetElement.offsetWidth / 2) - (checkerElement.offsetWidth / 2);
    const shiftY = targetY - currentPos.top;


    // Force absolute positioning to prevent flicker
    checkerElement.style.position = 'absolute';

    // Animate movement using CSS transition
    checkerElement.style.transition = 'transform 0.5s ease';
    checkerElement.style.transform = `translate(${shiftX}px, ${shiftY}px)`;

    const onTransitionEnd = () => {
        // Clean up the event listener
        checkerElement.removeEventListener('transitionend', onTransitionEnd);

        // Update the board state AFTER animation completes
        requestAnimationFrame(() => {
            currentPoint.checkers = currentPoint.checkers.filter((c) => c._id !== checker._id);
            targetPointObj.checkers.push({ ...checker, point: targetPoint });

            setMoves((prevMoves) => [
                ...prevMoves, 
                { checker, currentPoint: currentPoint.point, targetPoint }
            ]);

            setBoardState([...boardRef.current]);

            // Reset styles after React updates
            setTimeout(() => {
                checkerElement.style.transition = '';
                checkerElement.style.transform = '';
            }, 10);

            // Call the callback
            callback();
        });
    };

    // Listen for the end of the CSS transition
    checkerElement.addEventListener('transitionend', onTransitionEnd);
};

  
const ongoingVoidAnimations = new Map(); // Track ongoing animations for each target point
const ongoingOutgoingAnimations = new Map(); // Track outgoing animations for each point

const reverseMoveChecker = (checker, currentPoint, targetPoint, callback) => {
    const currentPointObj = boardRef.current.find((point) => point.point === currentPoint);
    const targetPointObj = boardRef.current.find((point) => point.point === targetPoint);

    if (!currentPointObj || !targetPointObj) {
        console.error('Invalid point for reverse move', currentPointObj, targetPointObj);
        callback();
        return;
    }

    const checkerElement = document.querySelector(`[data-checker-id="${checker._id}"]`);
    const currentElement = document.querySelector(`[data-point-id="${currentPoint}"]`);

    if (!checkerElement || !currentElement) {
        console.error('Checker or current point element not found');
        callback();
        return;
    }

    const currentPos = checkerElement.getBoundingClientRect();
    const currentTargetPos = currentElement.getBoundingClientRect();

    // Track ongoing incoming/outgoing animations
    const ongoingIncomingCount = ongoingVoidAnimations.get(currentPoint) || 0;
    const ongoingOutgoingCount = ongoingOutgoingAnimations.get(currentPoint) || 0;

    const targetStackLength = currentPointObj.checkers.length + ongoingIncomingCount - ongoingOutgoingCount;

    const checkerHeight = checkerElement.offsetHeight || 22;
    const maxCheckersWithoutOverlap = 4;
    const stackOffset = targetStackLength <= maxCheckersWithoutOverlap
        ? targetStackLength * checkerHeight
        : maxCheckersWithoutOverlap * checkerHeight;

    let targetY;
    if (currentPoint <= 12) {
        targetY = currentTargetPos.top + stackOffset;
    } else {
        targetY = currentTargetPos.bottom - checkerHeight - stackOffset;
    }

    const shiftX = currentTargetPos.left - currentPos.left + (currentElement.offsetWidth / 2) - (checkerElement.offsetWidth / 2);
    const shiftY = targetY - currentPos.top;



    // Force absolute positioning to prevent flicker
    checkerElement.style.position = 'absolute';

    // Animate movement
    checkerElement.style.transition = 'transform 0.5s ease';
    checkerElement.style.transform = `translate(${shiftX}px, ${shiftY}px)`;

    // Increment ongoing animation counts
    ongoingVoidAnimations.set(currentPoint, ongoingIncomingCount + 1);
    ongoingOutgoingAnimations.set(targetPoint, (ongoingOutgoingAnimations.get(targetPoint) || 0) + 1);

    const onTransitionEnd = () => {
        checkerElement.removeEventListener('transitionend', onTransitionEnd);

        // Update board state after animation completes
        requestAnimationFrame(() => {
            targetPointObj.checkers = targetPointObj.checkers.filter((c) => c._id !== checker._id);
            currentPointObj.checkers.push({ ...checker, point: currentPoint });

            setBoardState([...boardRef.current]);

            // Reset styles after React updates
            setTimeout(() => {
                checkerElement.style.transition = '';
                checkerElement.style.transform = '';
                checkerElement.style.position = '';
            }, 0);

            // Decrement animation counters
            ongoingVoidAnimations.set(currentPoint, ongoingVoidAnimations.get(currentPoint) - 1);
            ongoingOutgoingAnimations.set(targetPoint, ongoingOutgoingAnimations.get(targetPoint) - 1);

            if (ongoingVoidAnimations.get(currentPoint) === 0) {
                ongoingVoidAnimations.delete(currentPoint);
            }
            if (ongoingOutgoingAnimations.get(targetPoint) === 0) {
                ongoingOutgoingAnimations.delete(targetPoint);
            }

            callback();
        });
    };

    // Listen for animation completion
    checkerElement.addEventListener('transitionend', onTransitionEnd);
};



useEffect(() => {
   if (!game) return;

   if(isPlayerWaitingRef.current){
    
    isPlayerWaitingRef.current = false;
    setDice(game.dice);
}

}, [game]);  


    // Handle the Roll Dice Button click
const handleRollDice = async () => {
    
    handleRollAnimation();
    // Make API call to roll the dice on the server
    try {
        const response = await fetch('/api/roll-dice', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ gameId: game._id }), // Send game ID for context
        });

        if (!response.ok) {
            throw new Error('Error rolling the dice');
        }

    } catch (error) {
        console.error('Error during dice roll:', error);
    }
};

    const handleMove  = async (from, to) => {
        playBackgammonSound('moveChecker');
        try {
            await apiRequest('/api/move-checker', {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ gameId: game._id, from, to }),
            });
        } catch (error) {
            console.error('Error moving checker:', error);
        }
    };


    const handleConfirmMove = () => {
        setIsMoveConfirmed(true); 
        console.log("Moves confirmed, sending to server...");
      };
      
      
      
      const handleVoidMove = () => {
        if (isAnimating || moves.length === 0) return;
    
        const movesToReverse = [...moves];
        const checkerMoves = {}; 

        console.log('XXXXXXXXXXXXXXX',moves)
      
        movesToReverse.forEach((move) => {
            const { checker, targetPoint, currentPoint } = move;
            if (checkerMoves[checker._id]) {
                checkerMoves[checker._id].targetPoint = targetPoint;
            } else {
                checkerMoves[checker._id] = { targetPoint, currentPoint };
            }
        });

        Object.keys(checkerMoves).forEach((checkerId) => {
            const { targetPoint, currentPoint } = checkerMoves[checkerId];
            const checker = movesToReverse.find(move => move.checker._id === checkerId).checker;

            setIsAnimating(true);
            setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: true }));
            reverseMoveChecker(checker, currentPoint, targetPoint, () => {
                setIsAnimating(false); 
                setAnimatingCheckers((prev) => ({ ...prev, [checker._id]: false }));
            });

        });

        capturedCheckersRef.current = [];
        setMoves([]);  
        setUsedDice([false, false]);  
    };
    
    
    
    
    
      
      


    const renderVoidConfirmButtons = () => {
        if (isMoveConfirmed) {
            return null; // Do not show buttons if the move is already confirmed
        }
    
        // Check if both dice have been used, or if a double dice scenario requires all four actions
        const allDiceUsed = usedDice.every(dice => dice === true); // Checks if both dice are used
        const isDoubleDice = dice[0] === dice[1]; // Check if both dice are the same (double dice)
        const movesMade = moves.length; // Check how many moves were made
    
        // If it's double dice, we need to ensure 4 moves (2 for each die)
        const confirmMoveEnabled = (isDoubleDice && movesMade >= 4) || (!isDoubleDice && allDiceUsed);
    
        return (
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                {moves.length > 0 && (
                    <button onClick={handleVoidMove} style={{ marginBottom: '10px' }}>
                        Void
                    </button>
                )}
                {/* Render Confirm Move button if all dice are used or if double dice scenario has completed 4 moves */}
                {confirmMoveEnabled && (
                    <button onClick={handleConfirmMove}>Confirm</button>
                )}
            </div>
        );
    };
    

    const renderAvatar = (name, photoUrl) => {
        if (photoUrl) {
            return <img src={photoUrl} alt={`${name}'s Avatar`} className="backgammon-avatar-image" />;
        }
        const initial = name.charAt(0).toUpperCase();
        return <div className="backgammon-avatar-placeholder">{initial}</div>;
    };
    
    const renderOpponentArea = (game, user, opponents) => {
        const opponent = opponents.find(opponent => opponent._id !== user._id);
    
        if (!opponent) {
            return null;
        }
    
    
    
        return (
            <div className={`backgammon-opponent-wrapper ${game.turn === opponent._id ? 'highlighted-turn' : ''}`}>
                <div className="backgammon-opponent-area">
                    <div className="backgammon-opponent-avatar">
                        {renderAvatar(opponent.nickname, opponent.photoUrl)}
    
                        <svg className="backgammon-timer-circle">
                            <defs>
                                <linearGradient id="backgammonGradient" x1="50%" y1="0%" x2="50%" y2="100%">
                                    <stop offset="0%" style={{ stopColor: '#FF6B6B', stopOpacity: 1 }} />
                                    <stop offset="100%" style={{ stopColor: '#FF9A9E', stopOpacity: 1 }} />
                                </linearGradient>
                            </defs>
                            <circle className="base-circle" cx="50%" cy="50%" r="45%" />
                            <circle className="timer-circle" cx="50%" cy="50%" r="45%" stroke="url(#backgammonGradient)" />
                        </svg>
                    </div>
    
                    <div className="backgammon-opponent-info">
                        <div className="backgammon-name-and-flag">
                            <span className="backgammon-opponent-name">{opponent.nickname}</span>
                            {opponent.country && (
                                <img
                                    src={`/flags/${opponent.country.toLowerCase()}.svg`}
                                    alt={`${opponent.country} flag`}
                                    className="backgammon-country-flag"
                                />
                            )}
                        </div>
                        <span className="backgammon-opponent-score">Score: {opponent.score}</span>
                        <span className="backgammon-opponent-checkers">Checkers Off: {game.borneOff[opponent._id]?.length || 0}</span>
                    </div>
                </div>
            </div>
        );
    };


    

const renderBoardArea = () => {
    if (!boardRef.current || boardRef.current.length === 0 || !game) return; // Ensure board data exists

    const isPlayerTurn = game.turn === user._id; // Check if it's the current player's turn

    return (
        <div className="backgammon-board-wrapper">
            <div className="backgammon-board-container">
                <div className="backgammon-board-border">
                    <div className="backgammon-board">
                        <div className="board-grid"></div>

                        {boardRef.current.map((point, index) => {
    const totalCheckers = point.checkers.length;
    const maxCheckersWithoutOverlap = 5;
    const maxStackHeight = 88;
    
    // Special case for points 25 and 26
    const isCapturedPoint = point.point === 25 || point.point === 26;
    
    const overlapSize = isCapturedPoint 
        ? 0 // Ensure full overlap, even for 2 checkers
        : totalCheckers > maxCheckersWithoutOverlap
            ? maxStackHeight / (totalCheckers - 1)
            : 22;

    return (
        <div
            key={index}
            className={`backgammon-point backgammon-point-${point.point}`} 
            data-point-id={point.point}
            style={{
                left: `${point.x}px`,
                top: `${point.y}px`,
            }}
            onClick={() => handlePointClick(index, usedDice[0] ? dice[1] : dice[0], game)}
        >
            {point.checkers.map((checker, checkerIndex) => {
                 const isMoving = animatingCheckers[checker._id];
                
                return (
                    <div
                        key={checker._id}
                        className={`backgammon-checker ${checker.color} ${selectedChecker?.point === point.point ? "selected" : ""}`}
                        data-checker-id={checker._id}
                        style={{
                            position: 'absolute',
                            zIndex: isAnimating ? 1000 : checkerIndex + 1,
                            ...(point.point <= 12
                                ? { top: `${checkerIndex * overlapSize}px` }
                                : { bottom: `${checkerIndex * overlapSize}px` }),
                        }}
                    >
                        <img
                            src={`/backgammonAssets/checker-${checker.color}.png`}
                            alt={`Checker ${checker.color}`}
                            style={{
                                width: '22px',
                                height: '22px',
                            }}
                        />

                        {/* Show the total number on the top checker for points 25 & 26 if there's more than 1 checker */}
                        {!animatingCheckers[checker._id] && checkerIndex === totalCheckers - 1 && 
                            ((isCapturedPoint && totalCheckers > 1) || (!isCapturedPoint && totalCheckers > maxCheckersWithoutOverlap)) && (
                            <div
                                className="backgammon-checker-number"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: checker.color === 'black' ? 'white' : 'black',
                                    fontSize: '11px',
                                    fontWeight: 'bold',
                                    zIndex: 1001,
                                }}
                            >
                                {totalCheckers}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
})}


            
{(isPlayerTurn || game?.phase === 'game-start') && (
    <div className="backgammon-dice-container player-dice">
        <div
            className={`backgammon-dice1 ${isSwapping ? 'switch-backgammon-dice' : ''}`}
            onClick={() => handleDiceClick()} 
            style={{
                opacity: !isRolling && game?.phase === 'roll-dice' ? 0.6 : (usedDice[0] === 1 && dice[0] === dice[1]) ? 0.8 : usedDice[0] ? 0.6 : 1,
                transition: 'transform 0.5s ease',
                display: (game?.phase === 'game-start' && isPlayerTurn) || game?.phase !=='game-start' ? 'block' : 'none', // Show during game start phase
            }}
        >
            <img
                src={`/backgammonAssets/dice-${dice[0]}.png`} // White dice for current player
                alt={`Dice ${dice[0]}`}
                style={{ width: '32px', height: '32px' }}
            />
        </div>

        <div
            className={`backgammon-dice2 ${isSwapping ? 'switch-backgammon-dice' : ''}`}  
            onClick={() => handleDiceClick()}
            style={{
                opacity: !isRolling && game?.phase === 'roll-dice' ? 0.6 : (usedDice[1] === 1 && dice[0] === dice[1]) ? 0.8 : usedDice[1] ? 0.6 : 1,
                transition: 'transform 0.5s ease',
                display: (game?.phase === 'game-start' && isPlayerTurn) ? 'none' : 'block', // Show during game start and roll-dice phases
            }}
        >
            <img
                src={`/backgammonAssets/dice-${dice[1]}.png`} // White dice for current player
                alt={`Dice ${dice[1]}`}
                style={{ width: '32px', height: '32px' }}
            />
        </div>
    </div>
)}

{(!isPlayerTurn || game?.phase === 'game-start') && (
    <div className="backgammon-dice-container opponent-dice">
        

        <div
            className="backgammon-dice1"
            style={{
                opacity: !isRolling && game?.phase === 'roll-dice' ? 0.6 : 1,
                display: (game?.phase === 'game-start' && !isPlayerTurn) || game?.phase !== 'game-start' ? 'block' : 'none', // Show during game start and roll-dice phases
            }}
        >
            <img
                src={`/backgammonAssets/OpponentDice-${dice[0]}.png`} // Black dice for opponent
                alt={`Opponent Dice ${dice[0]}`}
                style={{ width: '32px', height: '32px' }}
            />
        </div>

      
        <div
            className="backgammon-dice2"
            style={{
                opacity: !isRolling && game?.phase === 'roll-dice' ? 0.6 : 1,
                display: (game?.phase === 'game-start' && !isPlayerTurn)  ? 'none' : 'block', // Show during game start and roll-dice phases
            }}
        >
            <img
                src={`/backgammonAssets/OpponentDice-${dice[1]}.png`} // Black dice for opponent
                alt={`Opponent Dice ${dice[1]}`}
                style={{ width: '32px', height: '32px' }}
            />
        </div>
    </div>
)}



                        {/* Roll Dice Button (For Current Player) OR Waiting Button (For Opponent) */}
{game?.phase === 'roll-dice' && (
    <div
        className="backgammon-roll-button"
        onClick={isPlayerTurn ? handleRollDice : null}  // Only allow clicking if it's player's turn
        style={{
            cursor: isPlayerTurn ? 'pointer' : 'default',
            display: isRolling ? 'none' : 'block',
            left: isPlayerTurn ? '70%' : '19.5%', // Right half for current player, left half for opponent
        }}
    >
        <img
            src={
                isPlayerTurn && !isPlayerWaitingRef.current
                    ? "/backgammonAssets/roll-dice-button.png"
                    : "/backgammonAssets/waiting-button.png"
            }
            alt={isPlayerTurn ? "Roll Dice" : "Waiting for Opponent"}
            style={{ width: '35px', height: '35px', opacity: isPlayerTurn ? 1 : 0.6 }}
        />
    </div>
)}
{renderVoidConfirmButtons()}


                    </div>
                </div>
            </div>
        </div>
    );
};

    
    
    
    

    const renderPlayerArea = (game, user) => {
        if (!user) {
            return null; 
        }
    
        return (
            <div className={`backgammon-player-area ${game.turn === user._id ? 'highlighted-turn' : ''}`}>
                <div className="backgammon-player-avatar">
                    {renderAvatar(user.nickname, user.photoUrl)}
                    <svg className="backgammon-timer-circle">
                        <defs>
                            <linearGradient id="backgammonGradientPlayer" x1="50%" y1="0%" x2="50%" y2="100%">
                                <stop offset="0%" style={{ stopColor: '#199519', stopOpacity: 1 }} />
                                <stop offset="100%" style={{ stopColor: '#19E127', stopOpacity: 1 }} />
                            </linearGradient>
                        </defs>
                        <circle className="backgammon-base" cx="50%" cy="50%" r="45%" />
                        <circle className="backgammon-timerP" cx="50%" cy="50%" r="45%" stroke="url(#backgammonGradientPlayer)" />
                    </svg>
                </div>
    
                <div className="backgammon-player-info">
                    <div className="backgammon-name-and-flag">
                        <span className="backgammon-player-name">{user.nickname}</span>
                        {user.country && (
                            <img
                                src={`/flags/${user.country.toLowerCase()}.svg`}
                                alt={`${user.country} flag`}
                                className="backgammon-country-flag"
                            />
                        )}
                    </div>
                    <span className="backgammon-player-score">Score: {user.score}</span>
                    <span className="backgammon-player-checkers">Checkers Off: {game.borneOff[user._id]?.length || 0}</span>
                </div>
            </div>
        );
    };



        // Render cancel popup
        const renderPopup = () => {
            if (ShowCancelPopup) {
                return (
                    <div className="popup">
                        <div className="popup-inner">
                            <h3>Game Canceled</h3>
                            <p>The game has been canceled due to server maintenance. Your bet has been refunded.</p>
                            <button onClick={() => setShowCancelPopup(false)}>Close</button>
                        </div>
                    </div>
                );
            }
        };
    
    
        if (!game) {
            return (
                <div className="backgammon-container">
                    <h2>Loading game...</h2>
                </div>
            );
        }
        

    return (
        <div className="backgammon-container">
            {renderOpponentArea(game, user, opponents)}
            
            {renderBoardArea()}
            
    
            <div className="backgammon-player-wrapper">
                {renderPlayerArea(game, user)}
            </div>
    
            {renderPopup()}
        </div>
    );
    
};

export default BackgammonGame;
